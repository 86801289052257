import Link from "next/link";
import Image from "next/image";
import styled from "styled-components";
import IconFacebook from "lib/assets/facebook2.svg";
import IconLinkedin from "lib/assets/linkedin.svg";
import IconX from "lib/assets/x.png";
import IconYoutube from "lib/assets/youtube.png";
import IconInstagram from "lib/assets/instagram.svg";
import IconMedium from "lib/assets/medium.svg";
import useIsResMobile from "lib/hooks/useIsResMobile";
import { getAirbridgeTrackerLink } from "lib/airbridge";
import AicpaSocLogo from "lib/assets/aicpa-soc.svg";
import { ReactNode } from "react";

const SOC_LINK = "https://www.aicpa.org/soc4so";

const Footer = () => {
  const isResMobile = useIsResMobile();

  return (
    <Container
      css={`
        padding: 28px ${isResMobile ? 20 : 30}px ${isResMobile ? 75 : 30}px;
        line-height: ${isResMobile ? `18px` : `22px`};
        font-size: ${isResMobile ? 12 : 14}px;
        flex-grow: 0;
      `}
    >
      <Wrapper>
        {/* Menus UI */}
        <div
          css={`
            margin-bottom: 50px;
            max-width: ${isResMobile ? `100%` : `850px`};
            display: flex;
            flex-basis: ${isResMobile ? `100%` : `80%`};
            justify-content: space-between;
          `}
        >
          {!isResMobile && <FooterMenusInWideScreen />}
          {isResMobile && <FooterMenusInMobile />}
        </div>

        {/* Social & Legal UI */}
        <Socials isResMobile={isResMobile} />

        <div
          css={`
            display: ${isResMobile ? `block` : `flex`};
            flex-basis: ${isResMobile ? `70%` : `100%`};
            margin-top: 24px;
          `}
        >
          <Copyright>© {new Date().getFullYear()} Lawfully Inc.</Copyright>
          <Terms
            css={`
              margin-top: ${isResMobile ? 15 : 0}px;
            `}
          >
            <Link passHref href="/privacy-policy">
              <FooterLink>Privacy Policy</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="/terms-of-service">
              <FooterLink>Terms of Service</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="https://pro.lawfully.com/privacy-policy">
              <FooterLink>Privacy Policy (Lawfully Pro)</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="https://pro.lawfully.com/terms-of-service">
              <FooterLink>Terms of Service (Lawfully Pro)</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="/limited-scope-representation-agreement">
              <FooterLink>Limited Scope Representation Agreement</FooterLink>
            </Link>
          </Terms>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const FooterMenusInWideScreen = () => {
  return (
    <>
      <LinkList>
        <ListTitle>
          <Link passHref href="/case-tracker">
            <FooterLink>Case Tracker</FooterLink>
          </Link>
        </ListTitle>
        <ListItem>
          <a
            href={getAirbridgeTrackerLink(
              false,
              "Footer",
              undefined,
              "https://apps.apple.com/us/app/my-uscis-case-status-tracker/id1435063223",
            )}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
            id="Footer_CaseTracker_iOSAppDownload"
            css={`
              :hover {
                text-decoration: underline;
              }
            `}
          >
            iOS
          </a>
        </ListItem>
        <ListItem>
          <a
            href={getAirbridgeTrackerLink(
              false,
              "Footer",
              undefined,
              "https://play.google.com/store/apps/details?id=com.lawfully.lawfully_ai_tracker&hl=en_US&gl=US&pli=1",
            )}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
            id="Footer_CaseTracker_AndroidAppDownload"
            css={`
              :hover {
                text-decoration: underline;
              }
            `}
          >
            Android
          </a>
        </ListItem>
      </LinkList>
      <LinkList>
        <ListTitle>
          <Link passHref href="/resources">
            <FooterLink>Immigration 101</FooterLink>
          </Link>
        </ListTitle>
        <ListItem>
          <Link passHref href="/resources">
            <FooterLink>Immigration Q&A</FooterLink>
          </Link>
        </ListItem>
        <ListItem>
          <Link passHref href="/resources">
            <FooterLink>Marriage Green Card</FooterLink>
          </Link>
        </ListItem>
        <ListItem>
          <Link passHref href="/resources">
            <FooterLink>Citizenship</FooterLink>
          </Link>
        </ListItem>
      </LinkList>
      <LinkList>
        <ListTitle>
          <Link passHref href="/lawyers/states/all">
            <FooterLink>Find a Lawyer</FooterLink>
          </Link>
        </ListTitle>
        <ListTitle>
          <Link passHref href="/mock-interview">
            <FooterLink>Mock Interview</FooterLink>
          </Link>
        </ListTitle>
        <ListTitle>
          <Link passHref href="/community/categories/all">
            <FooterLink>Community</FooterLink>
          </Link>
        </ListTitle>
      </LinkList>
      <LinkList>
        <ListTitle>
          <Link passHref href="/about#about">
            <FooterLink>Company</FooterLink>
          </Link>
        </ListTitle>
        <ListTitle>
          <Link passHref href="/about#careers">
            <FooterLink>Careers</FooterLink>
          </Link>
        </ListTitle>
        <ListTitle>
          <Link passHref href="/for-lawyers">
            <FooterLink>For Lawyers</FooterLink>
          </Link>
        </ListTitle>
      </LinkList>
      <LinkList>
        <ListTitle>
          <Link passHref href="/privacy-policy">
            <FooterLink
              css={`
                white-space: nowrap;
              `}
            >
              Security & Compliance
            </FooterLink>
          </Link>
        </ListTitle>
        <ListItem>
          <Link passHref href={SOC_LINK}>
            <FooterLink>
              <AicpaSocLogo />
            </FooterLink>
          </Link>
        </ListItem>
      </LinkList>
    </>
  );
};

const FooterMenusInMobile = () => {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <MenuRowInMobile
        firstColumn={
          <MenuLinkInMobile label="Case Tracker" link="/case-tracker" />
        }
        secondColumn={
          <>
            <MenuLinkInMobile
              label="iOS"
              link={getAirbridgeTrackerLink(
                true,
                "Footer",
                undefined,
                "https://apps.apple.com/us/app/my-uscis-case-status-tracker/id1435063223",
              )}
            />
            <MenuLinkInMobile
              label="Android"
              link={getAirbridgeTrackerLink(
                true,
                "Footer",
                undefined,
                "https://play.google.com/store/apps/details?id=com.lawfully.lawfully_ai_tracker&hl=en_US&gl=US&pli=1",
              )}
            />
          </>
        }
        secondColumnCss={`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
      />
      <MenuRowInMobile
        firstColumn={
          <>
            <MenuLinkInMobile label="Immigration 101" link="/resources" />
            <MenuLinkInMobile label="Citizenship" link="/resources" />
          </>
        }
        secondColumn={
          <>
            <MenuLinkInMobile label="Immigration Q&A" link="/resources" />
            <MenuLinkInMobile label="Marriage Green Card" link="/resources" />
          </>
        }
      />
      <MenuRowInMobile
        firstColumn={
          <>
            <MenuLinkInMobile
              label="Find a Lawyer"
              link="/lawyers/states/all"
            />
            <MenuLinkInMobile label="Mock Interview" link="/mock-interview" />
          </>
        }
        secondColumn={
          <MenuLinkInMobile
            label="Community"
            link="/community/categories/all"
          />
        }
      />
      <MenuRowInMobile
        firstColumn={
          <>
            <MenuLinkInMobile label="Company" link="/about#about" />
            <MenuLinkInMobile label="Careers" link="/about#careers" />
          </>
        }
        secondColumn={
          <MenuLinkInMobile label="For Lawyers" link="/for-lawyers" />
        }
      />
      <MenuRowInMobile
        firstColumn={
          <>
            <MenuLinkInMobile
              label="Security & Compliance"
              link="https://www.lawfully.com/privacy-policy"
            />
            <MenuLinkInMobile label={<AicpaSocLogo />} link={SOC_LINK} />
          </>
        }
      />
    </div>
  );
};

const MenuRowInMobile = ({
  firstColumn,
  firstColumnCss,
  secondColumn,
  secondColumnCss,
}: {
  firstColumn?: ReactNode;
  firstColumnCss?: string;
  secondColumn?: ReactNode;
  secondColumnCss?: string;
}) => {
  return (
    <div
      css={`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #555;
      `}
    >
      <div
        css={
          firstColumnCss
            ? firstColumnCss
            : `
                display: flex;
                flex-direction: column;
                & * ~ * {
                  margin-top: 12px;
                }
              `
        }
      >
        {firstColumn}
      </div>
      <div
        css={
          secondColumnCss
            ? secondColumnCss
            : `
                display: flex;
                flex-direction: column;
                & * ~ * {
                  margin-top: 12px;
                }
              `
        }
      >
        {secondColumn}
      </div>
    </div>
  );
};

const MenuLinkInMobile = ({
  label,
  link,
}: {
  label: string | ReactNode;
  link: string;
}) => {
  return (
    <a
      css={`
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      `}
      target="_blank"
      rel="noreferrer"
      href={link}
    >
      {label}
    </a>
  );
};

const Socials = ({ isResMobile }: { isResMobile: boolean }) => {
  return (
    <div
      css={`
        margin-top: ${isResMobile ? 10 : 0}px;
        margin-bottom: ${isResMobile ? 0 : 10}px;
        flex-basis: ${isResMobile ? `55px` : `auto`};
      `}
    >
      <LinkList
        css={`
          display: ${isResMobile ? `flex` : `block`};
        `}
      >
        <ListItem>
          <SocialLink
            href="https://x.com/lawfullytweet"
            target="_blank"
            rel="noreferrer"
            title="X"
            id="Footer_Social_Twitter"
          >
            <div
              css={`
                margin-right: ${isResMobile ? 12 : 4}px;
                width: ${isResMobile ? "28px" : "auto"};
                display: flex;
                overflow: hidden;
              `}
            >
              <Image
                src={IconX}
                width={isResMobile ? 28 : 22}
                height={isResMobile ? 28 : 22}
              />
            </div>
            {!isResMobile && `X (Twitter)`}
          </SocialLink>
        </ListItem>
        <ListItem>
          <SocialLink
            href="https://www.facebook.com/lawfullytracker/"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
            id="Footer_Social_Facebook"
          >
            <IconFacebook
              width={isResMobile ? 28 : 22}
              height={isResMobile ? 28 : 22}
              viewBox="0 0 22 22"
              style={{ marginRight: isResMobile ? 12 : 4 }}
              color="white"
            />
            {!isResMobile && `facebook`}
          </SocialLink>
        </ListItem>
        <ListItem>
          <SocialLink
            href="https://www.linkedin.com/company/lawfully"
            target="_blank"
            rel="noreferrer"
            title="Linkedin"
            id="Footer_Social_LinkedIn"
          >
            <IconLinkedin
              width={isResMobile ? 28 : 22}
              height={isResMobile ? 28 : 22}
              viewBox="0 0 22 22"
              style={{ marginRight: isResMobile ? 12 : 4 }}
              color="white"
            />
            {!isResMobile && `LinkedIn`}
          </SocialLink>
        </ListItem>
        <ListItem>
          <SocialLink
            href="https://www.youtube.com/channel/UC7KQxY3u2Q2ESTMe7cePfpg"
            target="_blank"
            rel="noreferrer"
            title="Youtube"
            id="Footer_Social_Youtube"
          >
            <div
              css={`
                margin-right: ${isResMobile ? 12 : 4}px;
                width: ${isResMobile ? "28px" : "auto"};
                display: flex;
              `}
            >
              <Image
                src={IconYoutube}
                width={isResMobile ? 28 : 22}
                height={isResMobile ? 28 : 22}
              />
            </div>
            {!isResMobile && `YouTube`}
          </SocialLink>
        </ListItem>
        <ListItem>
          <SocialLink
            href="https://www.instagram.com/lawfully_official/"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
            id="Footer_Social_Instagram"
          >
            <IconInstagram
              width={isResMobile ? 28 : 22}
              height={isResMobile ? 28 : 22}
              viewBox="0 0 22 22"
              style={{ marginRight: isResMobile ? 12 : 4 }}
              color="white"
            />
            {!isResMobile && `Instagram`}
          </SocialLink>
        </ListItem>
        <ListItem>
          <SocialLink
            href="https://lawfully-official.medium.com/"
            target="_blank"
            rel="noreferrer"
            title="Medium"
            id="Footer_Social_Medium"
          >
            <IconMedium
              width={isResMobile ? 28 : 22}
              height={isResMobile ? 28 : 22}
              viewBox="0 0 22 22"
              style={{ marginRight: isResMobile ? 12 : 4 }}
              color="white"
            />
            {!isResMobile && `Medium`}
          </SocialLink>
        </ListItem>
      </LinkList>
    </div>
  );
};

const Container = styled.div`
  background: black;
  color: white;
  a {
    color: white;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 980px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LinkList = styled.ul`
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  margin-bottom: 20px;
  color: white;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const ListTitle = styled(ListItem)`
  font-weight: 700;
`;

const Terms = styled.div`
  color: #888;
  a,
  a:hover {
    color: #888;
  }
`;

const Copyright = styled(Terms)`
  margin-right: 30px;
  white-space: nowrap;
`;

const FooterLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const SocialLink = styled.a`
  display: flex;
  opacity: 0.85;
  transition: opacity 120ms ease-in-out;
  :hover {
    opacity: 1;
  }
`;
